import React, { useState, useEffect } from 'react';
import FloatingButton from './FloattingButton';
import FormOverlay from './FormOverlay';
import TitleForm from './TitleForm';
import SeparatorForm from './SeparatorForm';
import NameInputsForm from './NameInputsForm';
import PhoneInputForm from './PhoneInputForm';
import EmailInputForm from './EmailInputForm';
import MessageInputForm from './MessageInputForm';
import SubmitButtonForm from './SubmitButtonForm';

import saveSharedContact from '../../services/saveSharedContact';

interface ReceivedContactProps {
    username: string;
}

const ReceivedContact: React.FC<ReceivedContactProps> = ({ username }) => {
    const [isContainerVisible, setIsContainerVisible] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [errors, setErrors] = useState<{
        firstName: string;
        lastName: string;
        phone: string;
        email: string;
        message: string;
    }>({
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        message: '',
    });

    useEffect(() => {
        if (isContainerVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isContainerVisible]);

    const resetForm = () => {
        setFirstName('');
        setLastName('');
        setPhone('+33');
        setEmail('');
        setMessage('');
        setErrors({
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            message: '',
        });
    };

    const toggleContainer = () => {
        if (isContainerVisible) {
            resetForm();
        }
        setIsContainerVisible(!isContainerVisible);
    };

    const validate = () => {
        const newErrors: typeof errors = {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            message: '',
        };

        if (!firstName.trim()) {
            newErrors.firstName = 'Le prénom est obligatoire.';
        } else if (firstName.length > 50) {
            newErrors.firstName = 'Le prénom ne peut pas dépasser 50 caractères.';
        }

        if (!lastName.trim()) {
            newErrors.lastName = 'Le nom est obligatoire.';
        } else if (lastName.length > 50) {
            newErrors.lastName = 'Le nom ne peut pas dépasser 50 caractères.';
        }

        if (!phone.trim()) {
            newErrors.phone = 'Le numéro de téléphone est obligatoire.';
        } else if (!/^\d{10,15}$/.test(phone)) {
            newErrors.phone = 'Le numéro de téléphone doit être valide (10 à 15 chiffres).';
        }

        if (!email.trim()) {
            newErrors.email = 'L\'adresse email est obligatoire.';
        } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            newErrors.email = 'L\'adresse email doit être valide.';
        }

        if (message.length > 500) {
            newErrors.message = 'Le message ne peut pas dépasser 500 caractères.';
        }

        setErrors(newErrors);
        return Object.values(newErrors).every((error) => error === '');
    };

    const handleSubmit = async () => {
        if (validate()) {
            setIsSubmitting(true);
            const formattedData = {
                email,
                phone,
                message,
                lastname: lastName,
                firstname: firstName,
            };

            try {
                await saveSharedContact([formattedData], username);
                resetForm();
                setIsContainerVisible(false);
            } catch (error) {
                if (error instanceof Error) {
                    alert('Erreur lors de l\'envoi des données : ' + error.message);
                } else {
                    alert('Erreur inconnue lors de l\'envoi des données.');
                }
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <>
            <FloatingButton onClick={toggleContainer} />
            <FormOverlay isVisible={isContainerVisible} onClose={toggleContainer}>
                <TitleForm text="Partagez vos coordonnées" />
                <SeparatorForm />
                <NameInputsForm
                    firstName={firstName}
                    setFirstName={setFirstName}
                    lastName={lastName}
                    setLastName={setLastName}
                    errors={{
                        firstName: errors.firstName,
                        lastName: errors.lastName,
                    }}
                />
                <PhoneInputForm
                    phone={phone}
                    setPhone={setPhone}
                    error={errors.phone}
                />
                <EmailInputForm
                    email={email}
                    setEmail={setEmail}
                    error={errors.email}
                />
                <MessageInputForm
                    message={message}
                    setMessage={setMessage}
                    error={errors.message}
                />
                <SubmitButtonForm
                    onClick={handleSubmit}
                    isDisabled={isSubmitting}
                />
            </FormOverlay>
        </>
    );
}

export default ReceivedContact;
